import React, { useEffect, useRef, useState } from 'react';
import config from '../config';
import { GlobalStyles } from '../components/theme/GlobalStyles';
import Typography from '@material-ui/core/Typography';
import LockedAdornment from '../components/authentication/LockedAdornment';
import { CircularProgress, Container, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import './pages.css';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
  },
  inputWrapper: {
    display: 'flex',
    margin: theme.spacing(0, -1),

    '& > *': {
      margin: theme.spacing(0, 1),
    },

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, -0.25),

      '& > *': {
        margin: theme.spacing(0, 0.25),
      },
    },
  },
}));

const PinInput = ({ value, onChange, onComplete, disabled }) => {
  const classes = useStyles();
  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const current = value.length < refs.length ? refs[value.length].current : undefined;

  const handleFocus = i => () => {
    if (value.length > i) {
      onChange(value.substring(0, i));
    } else if (value.length < i) {
      refs[value.length].current.focus();
    }
  };

  const handleChange = e => {
    const nextValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]+/g, '');
    onChange(value + nextValue.slice(0, 8 - value.length));
  };

  const handleKeydown = i => e => {
    const key = e.keyCode || e.charCode;

    if (key === 8 || key === 46) {
      refs[i].current.focus();
      if (value.length > 0) {
        onChange(value.slice(0, i - 1));
      }
    }
  };

  useEffect(() => {
    if (current && !disabled) {
      current.focus();
    }
    if (value.length === 8) {
      onComplete(value);
    }
  }, [current, disabled, value, onComplete]);

  return (
    <div className={classes.inputWrapper}>
      {refs.map((ref, i) => (
        <TextField
          variant="filled"
          key={i}
          inputRef={ref}
          inputProps={{
            autoFocus: i === 0,
            onKeyDown: handleKeydown(i),
          }}
          onFocus={handleFocus(i)}
          value={value[i] || ''}
          onChange={handleChange}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

const Homepage = ({ history }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [error, setError] = useState();
  const [busy, setBusy] = useState();

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError();
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [error]);

  const logIn = async value => {
    setBusy(true);

    try {
      const result = await fetch(`${config.apiURL}/identity/session?pin=${encodeURIComponent(value)}`);

      if (result.ok) {
        history.push(`/questionnaire/${value}`);
      } else {
        setError(
          intl.formatMessage({ id: 'homepage.error.pin', defaultMessage: 'PIN is not valid, please try again.' })
        );
      }
    } catch (e) {
      console.error(e);
      setError(
        intl.formatMessage({
          id: 'homepage.error.unexpected',
          defaultMessage: 'Unexpected error ocurred, please try again later.',
        })
      );
    } finally {
      setValue('');
      setBusy(false);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <GlobalStyles />
      <LockedAdornment />
      <Typography variant="h1" align="center" gutterBottom>
        <FormattedMessage id="homepage.instructions" defaultMessage="Please enter your PIN" />
      </Typography>
      <Box my={2}>
        <PinInput value={value} onChange={setValue} onComplete={logIn} disabled={busy} />
      </Box>
      <Box my={1}>
        {busy && <CircularProgress />}
        {!busy && error && <Alert severity="error">{error}</Alert>}
      </Box>
      <Typography align="center">
        <FormattedMessage
          id="homepage.helper"
          defaultMessage="You can find the PIN in the text message you have received from the surgical center."
        />
      </Typography>
    </Container>
  );
};

export default Homepage;
