import React, { Fragment, useEffect, useState } from 'react'
import { GlobalStyles } from '../../pages/questionnaire/Questionnaire'
import Button from '@material-ui/core/Button'
import get from 'lodash/get'
import { useMutation } from 'react-apollo-hooks'
import { requestAuthenticationCode, verifyAuthenticationCode } from '../../graphql/schema/questionnaire'
import Box from '@material-ui/core/Box'
import { CircularProgress, Container, Link, makeStyles, TextField } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { differenceInMinutes } from 'date-fns'
import LockedAdornment from "./LockedAdornment"
import Alert from "@material-ui/lab/Alert"

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      margin: theme.spacing(1, 0),
    },
  },
  container: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
}));

const errorCodes = {
  EXPIRED: 'EXPIRED',
  INVALID: 'INVALID',
  ATTEMPTS: 'ATTEMPTS',
  FAILED_SENDING: 'FAILED_SENDING',
  UNEXPECTED: 'UNEXPECTED',
};

const errorLabels = {
  [errorCodes.EXPIRED]: 'Code expired, click here to send a new one',
  [errorCodes.INVALID]: 'Entered code is not valid. Please try again.',
  [errorCodes.ATTEMPTS]: 'Too many attempts. Please wait few minutes and try again.',
  [errorCodes.FAILED_SENDING]: 'Failed sending code. Please Try again.',
  [errorCodes.UNEXPECTED]: 'Unexpected error occurred. Please try again later.',
};

const getErrorLabel = key => {
  if (key in errorLabels) {
    return errorLabels[key];
  } else {
    return errorLabels[errorCodes.UNEXPECTED];
  }
};

const PendingVerification = ({ working, disabled, onSubmit, code, onCodeChange }) => {
  const classes = useStyles();

  return (
    <form
      className={classes.root}
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextField
        disabled={disabled}
        onChange={e => onCodeChange(e.target.value)}
        value={code}
        label="Enter Verification Code"
        variant="filled"
      />
      <Button
        color="primary"
        size="large"
        disabled={disabled || !code || code.length === 0}
        onClick={() => onSubmit(code)}
      >
        {working ? <CircularProgress color="inherit" size={26} /> : 'Verify'}
      </Button>
    </form>
  );
};

const VerificationError = ({ error, onResend, working, shouldResend }) => (
  <Alert
    severity="error"
    action={
      shouldResend && <Button variant="text" onClick={onResend} disabled={working}>Resend Code</Button>
    }
  >
    {getErrorLabel(error)}
  </Alert>
);

const CodeSentNotice = ({ numbers }) =>
  numbers ? (
    <Typography color="textSecondary">{`A text message with secret code has been sent to your mobile phone: ${numbers.map(
      number => number
    )}`}</Typography>
  ) : null;

const TokenAuthentication = ({ codeSentAt, setCodeSentAt, codeValidFor, onVerified }) => {
  const classes = useStyles();
  const [working, setWorking] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState('');
  const [numbers, setNumbers] = useState(null);

  const requestCode = useMutation(requestAuthenticationCode);
  const verifyCode = useMutation(verifyAuthenticationCode);

  const shouldResend = [errorCodes.EXPIRED, errorCodes.FAILED_SENDING, errorCodes.ATTEMPTS].includes(error);

  const submitToken = async token => {
    try {
      setWorking(true);
      const result = await verifyCode({ variables: { token } });
      setError(null);
      onVerified(get(result, 'data.verifyAuthenticationCode'));
    } catch (e) {
      if (get(e, 'message', '').indexOf('Code Invalid') > 0) {
        setError(errorCodes.INVALID);
      } else if (get(e, 'message', '').indexOf('Code Expired') > 0) {
        setError(errorCodes.EXPIRED);
      } else if (get(e, 'message', '').indexOf('Too Many Attempts') > 0) {
        setError(errorCodes.ATTEMPTS);
      } else {
        setError(errorCodes.UNEXPECTED);
      }
      setWorking(false);
    }
  };

  const resendCode = async () => {
    setWorking(true);
    try {
      const result = await requestCode();
      if (get(result, 'data.requestAuthenticationCode.status') === 'SKIP') {
        const session = get(result, 'data.requestAuthenticationCode.session');
        if (session && session.token) {
          onVerified(session);
        }
      } else {
        setCodeSentAt(new Date());
        setNumbers(get(result, 'data.requestAuthenticationCode.phoneNumbers', []));
      }

      setError(null);
    } catch (e) {
      console.log(e);
      setError(errorCodes.FAILED_SENDING);
    } finally {
      setWorking(false);
    }
  };

  const init = () => {
    if (!codeSentAt || differenceInMinutes(new Date(), codeSentAt) >= codeValidFor) {
      resendCode();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(init, []);

  return (
    <Fragment>
      <GlobalStyles />
      <Container maxWidth="xs" className={classes.container}>
        <LockedAdornment/>
        <Box mb={4} textAlign="center">
          <Typography variant="h3" gutterBottom>Two Step Verification</Typography>
          <Typography>In order to keep your data safe we need to verify your identity. Thank you for your understanding!</Typography>
        </Box>
        <Box mb={2}>
          {error ? (
            <VerificationError error={error} onResend={resendCode} working={working} shouldResend={shouldResend} />
          ) : (
            <CodeSentNotice numbers={numbers} />
          )}
        </Box>
        <PendingVerification
          working={working}
          disabled={working || shouldResend}
          onSubmit={submitToken}
          error={error}
          code={code}
          onCodeChange={setCode}
        />

        <Box mt={2}>
          <Typography variant="body2">
            Code not working?{' '}
            <Link component="button" variant="body2" onClick={resendCode}>
              Resend code
            </Link>
          </Typography>
        </Box>
      </Container>
    </Fragment>
  );
};

export default TokenAuthentication;
