import React, { useState } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import FormContext from '../FormContext';
import renderFormNode from '../renderFormNode';
import components from '../components';
import makeName from '../makeName';
import { useIntl } from 'react-intl';
import { FormHelperText, TextField } from '@material-ui/core';
import InputWrapper from '../InputWrapper';
import TranslatedMessage from '../TranslatedMessage';
import DistinguishTitle from '../DistinguishTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldType, validatorFn, ValidatorType } from '../utils/validators/validateRequired';
import ValidationWrapper from './ValidationWrapper';

const OneOfInput = ({ label, question, options, validators, distinguish, optional, explanation }) => {
  const intl = useIntl();
  const [error, setError] = useState(null);

  const rules = validators ? validators.map(type => validatorFn[type]?.(FieldType.OneOf) ?? (() => '')) : [];
  const required = !!validators?.find(e => e === ValidatorType.isRequired);

  return (
    <FormContext.Consumer>
      {({ name, value, onChange }) => {
        const selectedOption = isArray(value?.option) ? value?.option?.[0] : value?.option;
        const currentOption = options.find(option => (isArray(option) ? option[0] : option) === selectedOption);
        const ifSelected = isArray(currentOption) ? currentOption[1] : null;
        const handleChange = e => {
          const v = isArray(e) ? e[0] : e;
          const o = options.find(option => (isArray(option) ? option[0] : option) === v);
          onChange({
            option: isArray(o) ? [o[0], components()[o[1].type].defaultValue] : o,
            progress: o !== undefined ? (!optional ? 1 : 0) : 0,
          });
        };

        return (
          <ValidationWrapper value={selectedOption} rules={rules} error={error} setError={setError}>
            <InputWrapper>
              {distinguish && <DistinguishTitle title={distinguish} />}
              <Autocomplete
                id={label || question || 'questionnaire.oneof.choose'}
                options={options}
                autoHighlight
                getOptionLabel={option =>
                  option
                    ? intl.formatMessage({
                        id: isArray(option) ? option[0] : option,
                        defaultMessage: isArray(option) ? option[0] : option,
                      })
                    : option
                }
                onChange={(_, value) => handleChange(value)}
                value={isArray(get(value, 'option')) ? get(value, ['option', 0]) : get(value, 'option') || ''}
                renderOption={option => {
                  const l = isArray(option) ? option[0] : option;
                  return <React.Fragment>{intl.formatMessage({ id: l, defaultMessage: l })}</React.Fragment>;
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={`${intl.formatMessage({
                      id: label || question || 'questionnaire.oneof.choose',
                      defaultMessage: label || question,
                    })}${required ? ' *' : ''}`}
                    error={!!error}
                    helperText={
                      <>
                        {!!error && (
                          <FormHelperText style={{ color: 'red' }}>
                            <TranslatedMessage message={error} />
                          </FormHelperText>
                        )}
                        {explanation && <TranslatedMessage message={explanation} />}
                      </>
                    }
                    placeholder={intl.formatMessage({
                      id: 'questionnaire.oneof.choose',
                      defaultMessage: 'Choose an option',
                    })}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              {ifSelected && (
                <div style={{ marginTop: '1.75em' }}>
                  <FormContext.Provider
                    value={{
                      name: makeName(name, selectedOption),
                      value: get(value, ['option', 1]),
                      onChange: v => onChange({ option: [selectedOption, v], progress: !optional ? 1 : 0 }),
                    }}
                  >
                    {renderFormNode(ifSelected)}
                  </FormContext.Provider>
                </div>
              )}
            </InputWrapper>
          </ValidationWrapper>
        );
      }}
    </FormContext.Consumer>
  );
};

OneOfInput.defaultValue = { option: '', progress: 0 };

export default OneOfInput;
