import React, { Fragment, useState } from 'react';
import { graphql, Mutation } from 'react-apollo';
import useMap from 'use-object';
import { myFeedback, submitFeedback } from '../graphql/schema/rating';
import styled from 'styled-components';
import RatingWidget from '../sections/FeedbackForm/RatingWidget';
import FeedbackSuccessState from '../sections/FeedbackForm/FeedbackSuccessState';
import Unauthorized from './Unauthorized';
import logo from '../assets/images/ospitek_logo.png';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import googleIcon from '../assets/providers/google.svg';
import yelpIcon from '../assets/providers/yelp.svg';
import LocalePicker from '../components/LocalePicker';
import Box from '@material-ui/core/Box';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { muiThemeDark } from '../components/theme/muiTheme';
import { Container, CssBaseline, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const defaultValue = { rating: undefined, suggestion: undefined, error: false };

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    display: 'flex',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: theme.spacing(2),
  },
}));

const Logo = styled.img`
  max-height: 0.875rem;
`;

const FeedbackFormDiv = styled.div`
  border: 1px solid #3b446b;
  border-radius: 5px;
`;

const providers = {
  google: 'google',
  yelp: 'yelp',
};

const resolveRatingLink = (physician, hospital, caretaker, isCaretaker) =>
  isCaretaker
    ? caretaker
    : get(physician, 'enabled') && get(physician, 'link')
    ? { ...physician, personal: true }
    : hospital;

const FeedbackForm = ({ data = {} }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [{ rating, suggestion, error }, { set }] = useMap(defaultValue);
  const [promptExternalRating, setPromptExternalRating] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  if (data && data.loading) {
    return null;
  }

  if (data && data.error && data.error.message === 'GraphQL error: Selected entity does not exist') {
    return (
      <ThemeProvider theme={muiThemeDark}>
        <Unauthorized />
      </ThemeProvider>
    );
  }

  if (data && data.myFeedback && data.myFeedback.id) {
    return (
      <ThemeProvider theme={muiThemeDark}>
        <CssBaseline />
        <FeedbackSuccessState />
      </ThemeProvider>
    );
  }

  const { name: hospitalName } = get(data, 'patientHospital', {});
  const google = defaultTo(
    resolveRatingLink(
      get(data, 'myPhysician.reviewIntegrations.google', {}),
      get(data, 'patientHospital.reviewIntegrations.google', {}),
      get(data, 'patientHospital.caretakerReviewIntegrations.google', {}),
      get(data, 'isCaretaker', false)
    ),
    {}
  );
  const yelp = defaultTo(
    resolveRatingLink(
      get(data, 'myPhysician.reviewIntegrations.yelp', {}),
      get(data, 'patientHospital.reviewIntegrations.yelp', {}),
      get(data, 'patientHospital.caretakerReviewIntegrations.yelp', {}),
      get(data, 'isCaretaker', false)
    ),
    {}
  );
  const hasExternalReviewIntegration = (yelp && yelp.enabled) || (google && google.enabled);

  const onStarRatingChange = value => {
    set('rating', value);
    setIsFocused(true);
    const MIN_OSPITEK = 4;
    const MIN_HOSPITAL = 3;
    const isHospitalReview = get(data, 'isCaretaker', false);
    const minRatingValue = isHospitalReview ? MIN_HOSPITAL : MIN_OSPITEK;
    if (typeof value === 'number' && value >= minRatingValue && hasExternalReviewIntegration) {
      setPromptExternalRating(true);
    } else if (promptExternalRating) {
      setPromptExternalRating(false);
    }
  };

  const syncFeedback = (mutate, provider, personal) => async () => {
    if (provider === providers.google) {
      await mutate({
        variables: { rating, suggestion: personal ? 'Feedback left on Physician Google.' : 'Feedback left on Google.' },
      });
    } else if (provider === providers.yelp) {
      await mutate({
        variables: { rating, suggestion: personal ? 'Feedback left on Physician Yelp.' : 'Feedback left on Yelp.' },
      });
    }
  };

  return (
    <ThemeProvider theme={muiThemeDark}>
      <CssBaseline />
      <Container maxWidth="sm" className={classes.root}>
        <Mutation mutation={submitFeedback} refetchQueries={[{ query: myFeedback }]} onError={() => set('error', true)}>
          {mutate => (
            <form
              onSubmit={e => {
                e.preventDefault();
                mutate({ variables: { rating, suggestion } });
              }}
              className={classes.form}
            >
              {error && (
                <Alert severity="error">
                  <FormattedMessage
                    id="feedback.error"
                    defaultMessage="There was an error submitting the form. Please, try again. If the problem persists, contact the help desk staff."
                  />
                </Alert>
              )}

              <Box mt={2} display="flex" justifyContent="center">
                <LocalePicker compact isCaretaker={get(data, 'isCaretaker', false)} />
              </Box>

              <Box mt={2}>
                {hospitalName ? (
                  <Typography variant="h5" align="center">
                    {hospitalName}
                  </Typography>
                ) : null}
              </Box>

              <Box my="auto">
                <RatingWidget done={promptExternalRating} rating={rating} onChange={onStarRatingChange} />
              </Box>

              {promptExternalRating ? (
                <Fragment>
                  <Typography align="center" gutterBottom>
                    <FormattedMessage
                      id="feedback.share.question"
                      defaultMessage="Do you want to share your experience with others?"
                    />{' '}
                  </Typography>
                  {google.enabled && (
                    <Button
                      component="a"
                      color="primary"
                      size="large"
                      onClick={syncFeedback(mutate, providers.google, google.personal)}
                      startIcon={<img style={{ width: '1em' }} alt="google logo" src={googleIcon} />}
                      href={google.link}
                    >
                      {intl.formatMessage({
                        id: 'feedback.share.google',
                        defaultMessage: 'Leave a review on Google',
                      })}
                    </Button>
                  )}
                  {yelp.enabled && google.enabled && (
                    <Box my={1}>
                      <Typography align="center" color="textSecondary">
                        or
                      </Typography>
                    </Box>
                  )}
                  {yelp.enabled && (
                    <Button
                      component="a"
                      color="primary"
                      size="large"
                      onClick={syncFeedback(mutate, providers.yelp, yelp.personal)}
                      startIcon={<img style={{ width: '1em' }} alt="yelp logo" src={yelpIcon} />}
                      href={yelp.link}
                    >
                      {intl.formatMessage({ id: 'feedback.share.yelp', defaultMessage: 'Rate us on Yelp!' })}
                    </Button>
                  )}
                  <Box mt={2}>
                    <Button
                      variant="text"
                      size="large"
                      onClick={e => {
                        e.preventDefault();
                        setPromptExternalRating(false);
                      }}
                      fullWidth
                    >
                      {intl.formatMessage({ id: 'feedback.share.skip', defaultMessage: 'Skip' })}
                    </Button>
                  </Box>
                </Fragment>
              ) : (
                <div>
                  <FeedbackFormDiv>
                    <TextField
                      variant="filled"
                      name="suggestion"
                      label={intl.formatMessage({
                        id: 'feedback.suggestion.question',
                        defaultMessage:
                          'Would you like to leave a comment or tell us how we could have improved your experience?',
                      })}
                      inputProps={{
                        style: {
                          padding: '20px 0px',
                        },
                      }}
                      fullWidth
                      multiline
                      autoFocus={isFocused}
                      focused={isFocused}
                      rows={4}
                      value={suggestion}
                      onChange={e => set('suggestion', e.target.value)}
                    />
                  </FeedbackFormDiv>
                  <Box mt={2}>
                    <Button type="submit" size="large" color="primary" disabled={typeof rating !== 'number'} fullWidth>
                      {intl.formatMessage({ id: 'feedback.send', defaultMessage: 'Send Feedback' })}
                    </Button>
                  </Box>
                </div>
              )}
              <Box my={4} display="flex" justifyContent="center">
                <Logo src={logo} />
              </Box>
            </form>
          )}
        </Mutation>
      </Container>
    </ThemeProvider>
  );
};

export default graphql(myFeedback)(FeedbackForm);
