import React, { useEffect, useState } from 'react'
import TokenAuthentication from './TokenAuthentication'
import { useLocalStorage } from '@rehooks/local-storage'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import ConnectionProvider from '../../graphql/ConnectionProvider'
import { withRouter } from 'react-router'
import { useMutation } from 'react-apollo-hooks'
import { extendAuthenticationCode } from '../../graphql/schema/questionnaire'
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { Container, makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import LockedAdornment from "./LockedAdornment"

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
});

const withAuthentication = Component =>
  withRouter(props => {
    const classes = useStyles();
    const [sessionToken, setSessionToken, deleteSessionToken] = useLocalStorage(get(props, 'match.params.token'));
    const [codeSentAt, setCodeSentAt] = useLocalStorage(get(props, 'match.params.token') + '-codeSent');
    const codeValidFor = 5; // minutes
    const { token, validUntil } = sessionToken || {};
    // useLocalStorage doesn't work in Safari per this issue https://github.com/rehooks/local-storage/issues/22
    // this is a quickfix until package merges the fix
    const [transientSessionToken, setTransientSessionToken] = useState(token);
    const [isAuthenticated, setIsAuthenticated] = useState(!!transientSessionToken);
    const [justExpired, setJustExpired] = useState(false);

    const extendCode = useMutation(extendAuthenticationCode);

    useEffect(() => {
      const validityInMillis = validUntil ? new Date(validUntil).getTime() - new Date().getTime() : 0;

      if (validityInMillis <= 0) {
        deleteSessionToken();
        setTransientSessionToken(undefined);
        setIsAuthenticated(false);
        return;
      }

      const sessionTimeout = setTimeout(() => {
        setJustExpired(true);
        deleteSessionToken();
        setTransientSessionToken(undefined);
        setIsAuthenticated(false);
      }, validityInMillis);

      return () => {
        clearTimeout(sessionTimeout);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validUntil]);

    useEffect(() => {
      const validityInMillis = validUntil ? new Date(validUntil).getTime() - new Date().getTime() : 0;
      const refreshDeadline = validityInMillis - 2 * 1000;

      if (refreshDeadline <= 0) {
        deleteSessionToken();
        setTransientSessionToken(undefined);
        setIsAuthenticated(false);
        return;
      }

      const refresh = async () => {
        try {
          const result = await extendCode({ variables: { token } });
          const session = get(result, 'data.extendAuthenticationCode');
          setSessionToken(session);
          setTransientSessionToken(session && session.token);
          setIsAuthenticated(true);
        } catch (e) {
          console.error(e);
        }
      };

      const throttledRefresh = throttle(refresh, /* two minutes */ 2 * 60 * 1000, { trailing: true });

      const refreshDeadlineTimeout = setTimeout(() => throttledRefresh.flush(), refreshDeadline);

      window.addEventListener('mousemove', throttledRefresh);
      window.addEventListener('scroll', throttledRefresh);
      window.addEventListener('focus', throttledRefresh);
      window.addEventListener('blur', throttledRefresh);

      return () => {
        clearTimeout(refreshDeadlineTimeout);
        window.removeEventListener('mousemove', throttledRefresh);
        window.removeEventListener('scroll', throttledRefresh);
        window.removeEventListener('focus', throttledRefresh);
        window.removeEventListener('blur', throttledRefresh);
        throttledRefresh.cancel();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validUntil]);

    const onVerified = session => {
      setSessionToken(session);
      setTransientSessionToken(session && session.token);
      setIsAuthenticated(true);
    };

    const onExpired = () => {
      deleteSessionToken();
      setTransientSessionToken(undefined);
      setIsAuthenticated(false);
    };

    return justExpired ? (
      <Container maxWidth="sm" className={classes.container}>
        <Box my="auto" textAlign="center">
          <LockedAdornment/>
          <Typography variant="h3" component="h1" gutterBottom>Session expired due to inactivity</Typography>
          <Typography gutterBottom>You can continue filling the form after you authenticate again.</Typography>
          <Box mt={2}>
            <Button color="primary" size="large" onClick={() => setJustExpired(false)}>Continue</Button>
          </Box>
        </Box>
      </Container>
    ) : isAuthenticated ? (
      <ConnectionProvider accessToken={transientSessionToken} onUnauthorized={onExpired}>
        <Component {...props} onExpired={onExpired} />
      </ConnectionProvider>
    ) : (
      <TokenAuthentication
        codeSentAt={codeSentAt}
        setCodeSentAt={setCodeSentAt}
        codeValidFor={codeValidFor}
        onVerified={onVerified}
      />
    );
  });

export default withAuthentication;
