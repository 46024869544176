import { createMuiTheme } from '@material-ui/core/styles';
import { fade, responsiveFontSizes } from '@material-ui/core';
import createSpacing from '@material-ui/core/styles/createSpacing';

const spacing = createSpacing(8);

const swatches = {
  dark: '#000C3F',
  primary: '#00A7F7',
};

const typography = {
  fontFamily: [
    'Rubik',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontWeightBold: 500,
  h1: {
    fontSize: '2.448rem',
  },
  h2: {
    fontSize: '2.074rem',
    fontWeight: 400,
  },
  h3: {
    fontSize: '1.728rem',
  },
  h4: {
    fontSize: '1.44rem',
    fontWeight: 400,
  },
  h5: {
    fontSize: '1.2rem',
  },
  button: {
    fontWeight: 500,
    textTransform: 'none',
    fontSize: '1rem',
  },
};

const palette = {
  light: {
    type: 'light',
    primary: {
      main: swatches.primary,
    },
    text: {
      primary: swatches.dark,
      secondary: fade(swatches.dark, 0.6),
    },
  },
};

const props = {
  MuiButton: {
    variant: 'contained',
    // disableElevation: true,
  },
  MuiButtonBase: {
    // disableRipple: true,
  },
  MuiCheckbox: {
    color: 'primary',
  },
};

const muiFilledInput = {
  root: {
    backgroundColor: fade(swatches.dark, 0.05),

    '&$focused': {
      backgroundColor: fade(swatches.primary, 0.1),
    },
  },
  underline: {
    '&:hover:before': {
      borderBottom: `2px solid #000c3f`,
    },
    '&:before': {
      borderBottom: `2px solid #000c3f`,
    },
  },
  input: {
    paddingTop: spacing(4.5),
  },
};

const muiInputLabel = {
  filled: {
    transform: 'translate(12px, 16px) scale(1)',
    display: 'flex',
    alignItems: 'center',
    minHeight: '2em',
    width: 'calc(100% - 24px)',

    '&$shrink': {
      transform: 'translate(12px, 8px) scale(0.85)',
      right: 'unset',
      overflow: 'unset',
      whiteSpace: 'initial',

      '&$marginDense': {
        transform: 'translate(12px, 7px) scale(0.75)',
      },
    },
  },
};

export const muiThemeLight = responsiveFontSizes(
  createMuiTheme({
    palette: palette.light,
    props: props,
    typography: typography,
    overrides: {
      MuiAutocomplete: {
        inputRoot: {
          '&[class*="MuiFilledInput-root"]': {
            paddingTop: spacing(3.5),
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
          },
          '#root': {
            height: '100%',
            '& > main': {
              height: '100%',
            },
          },
        },
      },
      MuiButton: {
        containedPrimary: {
          color: '#101010',
        },
        containedSizeLarge: {
          padding: spacing(1, 2),
          fontSize: '1.125rem',
        },
      },
      MuiFilledInput: muiFilledInput,
      MuiFormLabel: {
        root: {
          color: fade(palette.light.text.primary, 1),

          '&$focused': {
            color: 'inherit',
          },
        },
      },
      MuiInputLabel: muiInputLabel,
      MuiMenuItem: {
        root: {
          whiteSpace: 'normal',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
        },
      },
    },
  })
);

export const muiThemeDark = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#00A7F7',
      },
      background: {
        default: '#000C3F',
        paper: '#1b2759',
      },
    },
    props: props,
    typography: typography,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
          },
          '#root': {
            height: '100%',
            '& > main': {
              height: '100%',
            },
          },
        },
      },
      MuiButton: {
        containedPrimary: {
          color: '#fff',
        },
        containedSizeLarge: {
          padding: spacing(1, 2),
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: `rgba(0, 12, 63, .05)`,
        },
        underline: {
          '&:hover:before': {
            borderBottom: `2px solid #000c3f`,
          },
          '&:before': {
            borderBottom: `2px solid #000c3f`,
          },
        },
      },
      MuiInputLabel: muiInputLabel,
      MuiMenuItem: {
        root: {
          whiteSpace: 'normal',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
        },
      },
    },
  })
);
